import React, { useState, useEffect, useRef, FC } from "react"
import Styles from "../styles/PostList.module.scss";
import PostItem from "../components/postItem";
import ActivityIndicator from "../components/atoms/ActivityIndicator";
import { connect } from "react-redux";
import { updateTeamPostList, updateFollowSportsPostList, AppState } from "../state/app";
import useInfiniteScroll from "../hooks/useInfiniteScroll";
import { SAMPLE_POST_LIST } from "../constant/const";
import { PostTimeLineType } from "../types/models/Post";
import client from "../apis/client";
import { TableHeaderIcon } from "../icons/TableHeaderIcon";
import { Link } from "gatsby";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";
import { useLocalSettings } from "../hooks/useLocalSettings";

type Props = {
    shouldRefresh: boolean,
    teamPostList: PostTimeLineType[],
    followSportsPostList: PostTimeLineType[],
    updateTeamPostList: ( postList: PostTimeLineType[] ) => void,
    updateFollowSportsPostList: ( postList: PostTimeLineType[] ) => void,
    url: {
        old: string,
        new: string
        default: string
    },
    tabIndex: number,
    setBelongToTeam?: ( bool: boolean ) => void
}

const PostListTemplate: FC<Props> = ( {
    shouldRefresh,
    teamPostList,
    followSportsPostList,
    updateTeamPostList,
    updateFollowSportsPostList,
    url,
    tabIndex,
    setBelongToTeam
} ) => {

    const postList = tabIndex === 0 ? teamPostList : followSportsPostList
    const updatePostList = ( postList__: PostTimeLineType[] ) => {
        tabIndex === 0 ?
            updateTeamPostList( postList__ ) :
            updateFollowSportsPostList( postList__ )
    }


    const [ refreshing, setRefreshing ] = useState( false )
    const [ goingBack, setGoingBack ] = useState( false )
    const [ fetchAtLeastOnce, setFetchAtLeastOnce ] = useState( false )
    const [ renderTrigger, setRenderTrigger ] = useState( false )
    const isLoggedIn = useIsLoggedIn()
    const localSettings = useLocalSettings( { deps: [] } )

    const postListRef = useRef( postList )
    useEffect( () => {
        postListRef.current = postList
    }, [ postList ] )

    const [ allDataRetrieved, setAllDataRetrieved ] = useState( false )
    const allDataRetrievedRef = useRef( allDataRetrieved )
    useEffect( () => {
        allDataRetrievedRef.current = allDataRetrieved
    }, [ allDataRetrieved ] )

    useEffect( () => {
        shouldRefresh && postList.length && refresh()
    }, [ shouldRefresh ] )

    useEffect( () => {
        if ( !isLoggedIn ) return
        postList.length === 0 ?
            initialFetchPostList() :
            setRenderTrigger( !renderTrigger )
    }, [ isLoggedIn ] )

    const goBack = async () => {
        if ( allDataRetrievedRef.current ) return
        setGoingBack( true )
        const pk_list = postListRef.current.map( post => post.pk )
        const params = {
            pk_list,
            team_post: tabIndex === 0
        }
        await client.get( `/api/${ url.old }`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: params
        } )
            .then( response => {
                response.data.additional_list.length === 0 && setAllDataRetrieved( true )
                const newPostList = postListRef.current.concat( response.data.additional_list )
                updatePostList( newPostList )
            } )
            .catch( error => console.log( { error } ) )
        setTimeout( () => setGoingBack( false ), 500 )

    }

    const refresh = async () => {
        setRefreshing( true )
        const pk_list = postList.map( post => post.pk )
        const params = {
            pk_list,
            team_post: tabIndex === 0
        }
        await client.get( `/api/${ url.new }`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: params
        } )
            .then( response => {
                updatePostList( response.data.new_list.concat( postList ) )
            } )
            .catch( error => {
                console.log( "Error", error )
            } )
        setTimeout( () => setRefreshing( false ), 500 )
    }



    const initialFetchPostList = async () => {
        const params = {
            team_post: tabIndex === 0
        }
        await client.get( `/api/${ url.default }`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            params: params
        } )
            .then( response => {
                setBelongToTeam && setBelongToTeam( response.data.team )
                const newPostList = ( response.data.object_list.length < 16 && tabIndex === 0 ) ?
                    [ ...response.data.object_list, ...SAMPLE_POST_LIST ] :
                    response.data.object_list
                updatePostList( newPostList )
                setFetchAtLeastOnce( true )

            } )
            .catch( error => console.log( "error", error ) );
    }

    const renderEmpty = () => {
        const sentence = tabIndex === 0 ?
            "新着のチーム内の投稿を表示します\nチームに所属していない場合は自分の投稿が表示されます" :
            "新着のフォロー中のスポーツの投稿を表示します"
        return (
            <div className={ Styles.empty_container }>
                <TableHeaderIcon style={ { margin: "16px 0", fontSize: 72, color: "rgba(0,0,0,0.3)" } } />
                <p style={ { color: "rgba(0,0,0,0.54)", marginBottom: 12 } }>{ sentence }</p>
                { tabIndex === 1 &&
                    <Link to="/user_update" style={ { marginBottom: 2, fontWeight: "bold" } }>フォローするスポーツの設定画面に飛ぶ</Link> }
                { tabIndex === 0 &&
                    <p style={ { color: "rgba(0,0,0,0.54)" } }>下に引っ張るか、リロードすると更新できます</p> }
            </div>
        )
    }

    const infiniteScroll = useInfiniteScroll( {
        container: typeof document === "undefined" ?
            null :
            document.querySelectorAll( "div[data-swipeable=true]" )[ tabIndex ] as HTMLElement,
        goBack,
        refresh,
        goingBack,
        refreshing,
    } )

    return (
        <div>
            { refreshing && <ActivityIndicator /> }
            <div className={ Styles.container }
                { ...infiniteScroll }>
                { !refreshing &&
                    <div className={ Styles.hidden_activity_indicator }>
                        <ActivityIndicator />
                    </div> }
                { fetchAtLeastOnce && postListRef.current.length === 0 && tabIndex === 1 && renderEmpty() }
                {/* { fetchAtLeastOnce && postList.length === 0 && tabIndex === 0 && renderExampleCase() } */ }
                { postListRef.current.map( post =>
                    <PostItem
                        key={ post.pk }
                        post={ post }
                        showUserName={ localSettings.showUserNameOnTimeline } />
                ) }
                { goingBack && <ActivityIndicator /> }
            </div>
        </div>
    );
}

// const SAMPLE_POST_LISTItemText = ( {
//     text
// }: { text: string } ) => (
//         <div style={ {
//             borderRadius: 4,
//             backgroundColor: "red",
//         } } >
//             <p style={ { color: "#fff", fontSize: 13, fontWeight: "bold" } }>
//                 { text }
//             </p>
//         </div >
//     )


const mapStateToProps = ( state: AppState ) => ( {
    teamPostList: state.app.teamPostList,
    followSportsPostList: state.app.followSportsPostList
} )

const mapDispatchToProps = {
    updateTeamPostList,
    updateFollowSportsPostList
}




export default connect(
    mapStateToProps,
    mapDispatchToProps
)( PostListTemplate )
