import React, { FC } from 'react'
import { PostTimeLineType } from "../types/models/Post"
import ReactModal from 'react-modal'
import { CloseIcon } from '../icons/CloseIcon';
import { LinkPageComponent } from './LinkPageComponent';
import TutorialModal from '../templates/TutorialModal';


ReactModal.setAppElement( "#___gatsby" );

const modalStyle: ReactModal.Styles = {
    overlay: {
        position: "fixed",
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: 9,
        textAlign: "center"
    },
    content: {
        position: "absolute",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100vw",
        height: "100vh",
        padding: 0,
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
    },

};

type Props = {
    posts: PostTimeLineType[]
    modalVisible: boolean
    setModalVisible: ( b: boolean ) => void
}

export const FixedPostsPopup: FC<Props> = ( { posts, modalVisible, setModalVisible } ) => {

    return (
        <ReactModal
            isOpen={ modalVisible }
            onRequestClose={ () => setModalVisible( false ) }
            shouldCloseOnOverlayClick={ false }
            shouldCloseOnEsc
            style={ modalStyle }
            contentLabel="Video">
            <div
                style={ { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" } }>
                <div style={ { position: "relative", width: "100%", maxWidth: 800, padding: "0px 8px" } }>
                    { posts.length ?
                        <>
                            <CloseIcon style={ {
                                position: "absolute",
                                top: -32,
                                right: 0,
                                fontSize: 28,
                                color: "#fff",
                                zIndex: 3
                            } } onClick={ () => setModalVisible( false ) } />
                            <div style={ { display: "flex", flexWrap: "wrap" } }>
                                { posts.map( post =>
                                    <div style={ { width: "100%", marginBottom: 6 } }>
                                        <LinkPageComponent
                                            key={ post.pk }
                                            fetching={ false }
                                            linkPageObject={ { ...post, author: post.output_author } } />
                                    </div> ) }
                            </div>
                        </> :
                        <TutorialModal
                            closeModal={ () => setModalVisible( false ) }
                            slideList={ [ {
                                title: "投稿詳細画面から、固定できます",
                                image: "how-to-fix-team-posts.png"
                            } ] } />
                    }
                </div>
            </div>
        </ReactModal>

    )
}
