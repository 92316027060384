import React from "react"
import { Link } from "gatsby"
import Styles from "../../styles/PostFormButton.module.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFeatherAlt } from '@fortawesome/free-solid-svg-icons'

const PostFormButton = () =>
    <Link to="/post_form">
        <button className={ Styles.container }>
            <FontAwesomeIcon className={ Styles.icon } icon={ faFeatherAlt } />
        </button>
    </Link>

export default PostFormButton
