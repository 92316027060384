import React, { FC, useState, useEffect } from 'react'
import { DownArrowIcon } from '../icons/DownArrowIcon';
import Image from './image';
import ReactModal from 'react-modal'
import TutorialModal from '../templates/TutorialModal';


ReactModal.setAppElement( "#___gatsby" );

const modalStyle: ReactModal.Styles = {
    overlay: {
        position: "fixed",
        bottom: 0,
        right: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "rgba(0,0,0,0.7)",
        zIndex: 9,
        textAlign: "center"
    },
    content: {
        position: "absolute",
        display: "flex",
        justifyContent: 'center',
        alignItems: 'center',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        width: "100vw",
        height: "100vh",
        padding: 0,
        backgroundColor: "rgba(0,0,0,0)",
        border: "none",
    },

};

type Props = {
}

export const HowToFixTeamPostComponent: FC<Props> = ( { } ) => {

    const [ showImage, setShowImage ] = useState( false );

    return (
        <div>
            <div
                style={ {
                    display: "flex",
                    alignItems: "baseline",
                    marginBottom: 8,
                    color: "rgba(0,0,0,0.7)",
                    cursor: "pointer"
                } }
                onClick={ () => setShowImage( !showImage ) }>
                <h2 style={ {
                    marginRight: 8,
                    color: "rgba(0,0,0,0.45)"
                } }>
                    やり方
                </h2>
                <DownArrowIcon
                    style={ { transform: showImage ? "rotate(180deg)" : "none", color: "rgba(0,0,0,0.45)" } } />
            </div>
            {showImage &&
                <ReactModal
                isOpen={ showImage }
                    onRequestClose={ () => setShowImage( false ) }
                    shouldCloseOnOverlayClick={ false }
                    shouldCloseOnEsc
                    style={ modalStyle }
                    contentLabel="Video">
                    <div
                        style={ { width: "100%", height: "100%", display: "flex", justifyContent: "center", alignItems: "center" } }>
                        <TutorialModal
                            closeModal={ () => setShowImage( false ) }
                            slideList={ [ {
                                title: "投稿詳細画面から、固定できます",
                                image: "how-to-fix-team-posts.png"
                            } ] } /> 
                    </div>
                </ReactModal>}
        </div>
    )
}
