import React, { useState, useEffect, useMemo } from "react"
import { navigate } from "gatsby";
import Layout from "../components/Layout"
import SEO from "../components/seo"
import PostFormButton from "../components/atoms/PostFormButton";
import PostListTemplate from "../templates/PostList";
import MySwipableViews from "../components/MySwipableViews";
import { TagIcon } from "../icons/TagIcon";
import { InfoCircleIcon } from "../icons/InfoCircleIcon";
import Styles from "../styles/home.module.scss"
import { DownArrowIcon } from "../icons/DownArrowIcon";
import clsx from "clsx"
import { getFixedPosts } from "../apis/post/postFix";
import { PostTimeLineType } from "../types/models/Post";
import { FixedPostsPopup } from "../components/FixedPostsPopup"
import { useIsBigScreen } from "../hooks/useIsBigScreen";
import { LinkPageComponent } from "../components/LinkPageComponent";
import { linkPageObjectType } from "../components/PageLinkText";
import { HowToFixTeamPostComponent } from "../components/HowToFixTeamPostComponent";
import { useIsLoggedIn } from "../hooks/useIsLoggedIn";



const HomePage = ( {
    location
}: any ) => {

    const [ fixedPosts, setFixedPosts ] = useState<PostTimeLineType[]>( [] );
    const [ fetchingFixedPosts, setFetchingFixedPosts ] = useState( false );
    const [ showFixedPosts, setShowFixedPosts ] = useState( false );
    const isLoggedIn = useIsLoggedIn()

    const initialTabIndex = Number( location.search.split( '?tab=' )[ 1 ] ) || 0
    typeof window !== 'undefined' && window.history.replaceState( '', '', `${ location.pathname }?tab=${ initialTabIndex }` )

    const [ belongToTeam, setBelongToTeam ] = useState( true )
    const [ shouldRefresh, setShouldRefresh ] = useState( false );
    const isBigScreen = useIsBigScreen()

    useEffect( () => {
        if ( !isLoggedIn ) return
        fetchTeamFixedPosts()
        location && location.state && location.state.afterPost && setShouldRefresh( true )
    }, [ isLoggedIn ] )

    const fetchTeamFixedPosts = async () => {
        setFetchingFixedPosts( true )
        try {
            const { data } = await getFixedPosts()
            setFixedPosts( data.fixed_posts )
        }
        catch ( error ) {
            console.log( error )
        }
        setFetchingFixedPosts( false )
    }

    const tabList = [
        belongToTeam ? "チーム内" : "自分の投稿",
        "フォロー中のスポーツ"
    ]

    const headerOption = {
        headerTitle: "ClubCloud",
        headerTitleStyle: { fontSize: "1.2rem" },
        rightIcon: <TagIcon style={ { fontSize: 20 } } onClick={ () => navigate( "/tag_management" ) } />,
        leftIcon: <InfoCircleIcon style={ { fontSize: 20 } } onClick={ () => navigate( "/contact" ) } />,
        hideBorder: true
    }

    const PCFixedPostsMemo = useMemo( () =>
        <div className={ Styles.PC_fixed_posts_container }>
            <h2 className={ Styles.label }>チームで固定の投稿</h2>
            { !fixedPosts.length &&
                <div style={ { display: "flex" } }>
                    <HowToFixTeamPostComponent />
                </div> }
            { fixedPosts.map( post =>
                <div className={ Styles.fixed_post_item }>
                    <LinkPageComponent
                        fetching={ false }
                        linkPageObject={ { ...post, author: post.output_author } as unknown as linkPageObjectType } />
                </div> ) }
        </div>
        , [ fixedPosts ] )

    const showFixedPostsBelowHeader = !isBigScreen && belongToTeam
    const showFixedPostsInRightColumn = isBigScreen && belongToTeam

    return (
        <Layout
            headerOption={ headerOption }
            rightColumnContent={ showFixedPostsInRightColumn ?
                PCFixedPostsMemo :
                undefined }>
            <>
                <SEO title="Home" />
                {showFixedPostsBelowHeader &&
                    <div className={ Styles.fixed_post_container }>
                        <span
                            style={ { cursor: "pointer" } }
                            onClick={ () => setShowFixedPosts( !showFixedPosts ) }
                        >
                            チームで固定の投稿
                        <span className={ Styles.fixed_post_count }>
                                { fetchingFixedPosts ? "-" : fixedPosts.length }
                            </span>
                        件
                        <DownArrowIcon
                                className={ clsx( Styles.arrow_icon, showFixedPosts && Styles.posts_show_icon ) } />
                        </span>
                        <div className={ Styles.fixed_posts_content }>
                            { showFixedPosts &&
                                <FixedPostsPopup
                                    modalVisible={ showFixedPosts }
                                    setModalVisible={ setShowFixedPosts }
                                    posts={ fixedPosts } /> }
                        </div>
                    </div> }
                {showFixedPostsBelowHeader && <div style={ { height: 24 } }></div> }
                <MySwipableViews
                    tabList={ tabList }
                    location={ location }
                    initialTabIndex={ initialTabIndex }>
                    <PostListTemplate
                        url={ { default: 'post/', new: 'post_list/new/', old: 'post_list/old/' } }
                        tabIndex={ 0 }
                        setBelongToTeam={ setBelongToTeam }
                        shouldRefresh={ shouldRefresh } />
                    <PostListTemplate
                        url={ { default: 'post/', new: 'post_list/new/', old: 'post_list/old/' } }
                        tabIndex={ 1 }
                        shouldRefresh={ false } />
                </MySwipableViews>
                <PostFormButton />
            </>
        </Layout>
    )
}

export default HomePage

